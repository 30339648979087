<template>
  <section class="features--item ax--card text-center px-3">
    <img :src="data.img" alt="" class="w-100 features--img mt-3"/>
    <div class="features--info d-flex flex-column">
      <h2 class="features--info__title">{{ data.title }}</h2>
      <p class="features--info__description">
        {{ data.description }}
      </p>
    </div>
  </section>
</template>

<script>
import AxButton from "../AxButton";
export default {
  components: {AxButton},
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/scss/components/home/home-features";
</style>
