import { createStore } from 'vuex'
import Home from './modules/home';
import Auth from './modules/auth';
import Country from './modules/country';
import Setting from './modules/setting';
import Order from './modules/order';
import Currency from './modules/currency';
import User from './modules/user';
import Action from './modules/action';
import BankAccount from './modules/bankaccount';
import Price from './modules/price';
import MobileSidebar from './modules/mobileSidebar';
import Gifcard from './modules/giftcard';
import GiftcardSettings from './modules/giftcardSettings';

export default createStore({
  modules: {
    Home,
    Auth,
    Country,
    Setting,
    Order,
    Currency,
    User,
    Action,
    BankAccount,
    Price,
    MobileSidebar,
    Gifcard,
    GiftcardSettings
  }
});
