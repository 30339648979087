import RepositoryFactory from "../../api/RepositoryFactory";
import { buildLabledList } from "../../utils";

export default {
    state: {
        currencys: {}
    },

    mutations: {
        SET_CURRENCYS(state, payload) {
          state.currencys = buildLabledList(payload, 'code');
        }
    },

    actions: {
        FETCH_CURRENCYS(context) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('currency').all()
                .then(({ data }) => {
                    context.commit('SET_CURRENCYS', data)
                    resolve(data)
                })
                .catch(e => reject(e))
            })
        }
    },

    getters: {
        CURRENCYS: (state) => {
            return state.currencys;
        }
    }
}
