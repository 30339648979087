<template>
  <div :class="`alert alert-${type}`" role="alert">
    <p v-if="errors.length" v-for="error in errors">{{error[field] + ' ' + error[value]}}</p>
    <p v-else>{{value}}</p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'danger'
    },
    errors: {
      type: Array,
      default: []
    },
    field: {
      type: String,
      default: null
    },
    value: {
      type: String,
      required: true
    }
  },
}
</script>

<style scoped>

</style>