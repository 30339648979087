import RepositoryFactory from "../../api/RepositoryFactory";

export default {
    state: {
        types: [],
        accounts: []
    },
    mutations: {
        SET_ACCOUNT_TYPES(state, data) {
            state.types = data;
        },
        SET_ACCOUNTS(state, data) {
            state.accounts = data;
        },
        ADD_ACCOUNT(state, account) {
            state.accounts.push(account);
        },
    },
    actions: {
        FETCH_ACCOUNT_TYPES({ commit }) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('bankaccount').getAccountTypes().then(({ data }) => {
                    commit('SET_ACCOUNT_TYPES', data)
                    resolve()
                }).catch(error => reject(error))
            })
        },
        FETCH_ACCOUNTS({ commit }) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('bankaccount').getAccounts().then(({ data }) => {
                    commit('SET_ACCOUNTS', data)
                    resolve()
                }).catch(error => reject(error))
            })
        },
        CREATE_ACCOUNT({ commit }, accountForm) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('bankaccount').createAccounts(accountForm).then(({ data }) => {
                    commit('ADD_ACCOUNT', data)
                    resolve()
                }).catch(error => reject(error))
            })
        },
    },
    getters: {
        ACCOUNT_TYPES: (state) => {
            return state.types
        },
        ACCOUNTS: (state) => {
            return state.accounts
        }
    }
}
