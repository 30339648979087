<template>
  <div>
    <ax-header/>
    <section className="container mt-5">
      <slot/>
    </section>
  </div>
</template>

<script>
import AxHeader from "../components/_partials/_header";

export default {
  components: {AxHeader}
}
</script>

<style scoped>

</style>