<template>
  <div :key="dropdownKey">
    <label 
      :for="`input-${id}`" 
      :class="`form-label ${labelClass}`" 
      v-if="label"
    >{{ label }}</label>
    <div class="input-group input--append__style ax--select__dropdown">
      <input 
          v-if="input"
          :type="type"
          :min="min"
          :class="`form-control ${inputClass} ${errorClass}`"
          :disabled="disabled"
          :name="name"
          :id="`input-${id}`"
          :placeholder="placeholder"
          @input="$emit('update:modelValue', $event.target.value)"
          :value="modelValue"
        >
        <ax-button 
          variant="gray" 
          class="dropdown-toggle rounded-10  no-hover" 
          :class="[{'opacity-1':buttonDisabled}, buttonClass]" 
          :disabled="buttonDisabled"
          type="button" 
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <SvgIcon 
            :name="selected.code.toLowerCase()" 
            width="24" 
            height="24" 
            class="me-2" 
          />
        {{ selected.label }}
      </ax-button>
      <ul class="dropdown-menu dropdown-menu-end">
        <li v-for="(option, key) in options" :key="key">
          <a class="dropdown-item" href="#" @click.prevent="selected = option">
            <SvgIcon 
              :name="option.code.toLowerCase()" 
              width="24" 
              height="24" 
              class="me-2" 
            />
            {{ option.label }}
          </a>
        </li>
      </ul>
    </div>
    <div class="invalid-feedback_" v-if="error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon";
import AxButton from "./AxButton";
import { onlyNumber } from "../utils";

export default {
  components: {AxButton, SvgIcon},

  emits:['selected', 'update:modelValue'],

  props: {
    placeholder: {
      type: String,
      default: ''
    },
    
    name: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'text'
    },

    min: {
      type: Number,
      default: 0
    },

    input: {
      type: Boolean,
      default: true
    },

    id: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    appendText: {
      type: String,
      default: ''
    },

    inputClass: {
      type: String,
      default: ''
    },

    labelClass: {
      type: String,
      default: ''
    },

    buttonClass:{
      type: String,
      default: ''
    },

    options: {
      type: Array,
      default: function () {
        return []
      }
    },

    buttonDisabled: {
      type: Boolean,
      default: false
    },

    active: {
      type: Object,
      default: null
    },

    error: {
      type: Object,
      default: null
    },

    modelValue: [Number, String]
  },

  data() {
    return {
      selected: this.options && this.options[0],
      dropdownKey: Date.now()
    }
  },

  computed: {
    hasError(){
      return this.error && this.error.$error;
    },

    errorMessage(){
      if(this.hasError)
        return this.error.$errors[0].$message;
      return '';
    },

    errorClass(){
      if(this.hasError)
        return 'is-invalid';
      return '';
    }
  },

  watch: {
    selected: function (val) {
      this.$emit('selected', val);
    },

    active(newVal) {
      this.selected = newVal;
      this.dropdownKey = Date.now();
    }
  },

  created(){
    if(this.active)
      this.selected = this.active;
  }

}
</script>

<style scoped lang="scss">
@import "src/scss/components/_input.scss";

.invalid-feedback_ {
  font-size: 1rem;
  color: #dc3545;
  margin-top: 0.25rem;
}
</style>
