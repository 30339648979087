export const positive = {
  $validator: value => value > 0,
  $message: 'Value must be non zero'
}

export const min_amount = 0.000001;
export const max_amount = 10000;

export const min_amount_tl_ngn = 0.01;
export const max_amount_tl_ngn = 50000000;

export const inAllowedRange = {
  $validator: value => value >= min_amount && value <= max_amount,
  $message: `Value must be between ${min_amount} and ${max_amount}`
};

export const inAllowedRangeTlNgn = {
  $validator: value => value >= min_amount_tl_ngn && value <= max_amount_tl_ngn,
  $message: `Value must be between ${min_amount_tl_ngn} and ${max_amount_tl_ngn}`
};

export const password = {
  $validator: value => {
    const status = /[0-9a-zA-Z.!@#$%^&]{12,}/.test(value) || value === "" || value === undefined;
    return status
  },
  $message: `Password must contain at least 12 characters`
};
