<template>
  <header id="ax--header" :class="{ 'sticky-top': fixed}">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <router-link :to="{ name: 'Home'}" class="navbar-brand">
          <img src="/img/logo.svg" alt="logo" />
        </router-link>
        <button 
          class="navbar-toggler" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#navbarSupportedContent" 
          aria-controls="navbarSupportedContent" 
          aria-expanded="false" 
          aria-label="Toggle navigation"
          @click="onMenuClick"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" :class="{ show: showNav }">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 mt-3 mt-lg-0 align-items-end">
            <template v-if="!IS_AUTHENTICATED">
              <li class="nav-item pe-3">
                <ax-button :to="{name : 'Login'}" class="px-5 mb-2 mb-lg-0" variant="light-bordered">Login</ax-button>
              </li>
              <li class="nav-item">
                <ax-button :to="{name : 'Register'}" class="px-5">Sign Up</ax-button>
              </li>
            </template>
            <template v-else>
              <li class="nav-item pe-3 d-flex align-items-center cursor-pointer mb-2 mb-lg-0" @click="profile">
                <div class="rounded-circle avatar d-flex align-items-center justify-content-center">
                  <SvgIcon name="avatar" />
                </div>
                <span class="ms-1">
                  {{AUTH_USER.first_name}} {{AUTH_USER.last_name}}
                </span>
              </li>
              <li class="nav-item">
                <ax-button @click="logOut" variant="gray" class="px-5">Log Out</ax-button>
              </li>
            </template>

          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import AxButton from "../AxButton";
import SvgIcon from "../SvgIcon";
import {mapActions, mapGetters} from "vuex";

export default {

  components: {AxButton, SvgIcon},

  props: {
    fixed: {
      type: Boolean,
      default: false
    },
  },

  methods:{
    ...mapActions([
      'LOGOUT',
      'TOGGLE_MOBILE_SIDEBAR'
    ]),

    logOut(){
      this.LOGOUT()
      this.$router.push({name: 'Home'})
    },

    profile(){
      this.$router.push({name: 'Dashboard'})
    },

    onMenuClick(){
      this.TOGGLE_MOBILE_SIDEBAR();
      console.log(this.SHOW_MOBILE_SIDEBAR);
      //this.toggleNav();
      //this.showNav = this.SHOW_MOBILE_SIDEBAR;
    },

  },

  computed:{
    ...mapGetters([
      'IS_AUTHENTICATED', 
      'AUTH_USER',
      'SHOW_MOBILE_SIDEBAR',
      
    ]),

    showNav(){
      return this.SHOW_MOBILE_SIDEBAR;
    }
  },
}
</script>

<style scoped lang="scss">
@import "./src/scss/components/header";
</style>
