import AuthRepository from './repositories/AuthRepository';
import CountryRepository from './repositories/CountryRepository';
import SettingRepository from "./repositories/SettingRepository";
import OrderRepository from "./repositories/OrderRepository";
import CurrencyRepository from "./repositories/CurrencyRepository";
import HistoryRepository from "./repositories/HistoryRepository";
import UserRepository from "./repositories/UserRepository";
import ActionRepository from "./repositories/ActionRepository";
import BankAccountRepository from "./repositories/BankAccountRepository";
import NewsRepository from "./repositories/NewsRepository";
import PushToken from "./repositories/PushTokenRepository";
import PriceRepository from "./repositories/PriceRepository";
import PMRepository from "./repositories/PMRepository";
import RewardRepository from "./repositories/RewardRepository";
import GiftcardRepository from "./repositories/GiftcardRepository";
import GiftcardSettingRepository from "./repositories/GiftcardSettingRepository";

const repositories = {
    'auth': AuthRepository,
    'country': CountryRepository,
    'setting': SettingRepository,
    'order': OrderRepository,
    'currency': CurrencyRepository,
    'history': HistoryRepository,
    'user': UserRepository,
    'action': ActionRepository,
    'bankaccount': BankAccountRepository,
    'news': NewsRepository,
    'pushtoken': PushToken,
    'price': PriceRepository,
    'pm': PMRepository,
    'rewards': RewardRepository,
    'giftcard': GiftcardRepository,
    'giftcardSettings': GiftcardSettingRepository
}

export default {
    get: name => repositories[name]
};
