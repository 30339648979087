import Repository from './../Repository';

const resource = '/users';

export default {
    getUserLogs() {
        return Repository.get(`${resource}/logs`);
    },
    getUserProfile() {
        return Repository.get(`${resource}/profile`);
    },
    getUserCryptoAddresses() {
        return Repository.get("cryptowallet");
    }
};
