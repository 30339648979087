<template>
  <nav :class="'col-md-3 col-lg-2 sidebar collapse ' + displayClass">
    <div class="position-sticky">
      <ul class="nav flex-column">
        <li class="nav-item" @click="onMobileNavigation">
          <router-link :to="{ name: 'Dashboard' }" active-class="active">
            <SvgIcon name="dashboard" stroke="#7A94B8"/>
            Dashboard
          </router-link>
        </li>
        <li class="nav-item" @click="onMobileNavigation">
          <router-link :to="{ name: 'Buy' }" active-class="active">
            <SvgIcon name="arrow-long-down" stroke="#7A94B8" />
            Buy
          </router-link>
        </li>
        <li class="nav-item" @click="onMobileNavigation">
          <router-link :to="{ name: 'Sell' }" active-class="active">
            <SvgIcon name="arrow-long-up" stroke="#7A94B8" />
            Sell
          </router-link>
        </li>
        <li class="nav-item" @click="onMobileNavigation">
          <router-link :to="{ name: 'Exchange' }" active-class="active" >
            <SvgIcon name="exchange" stroke="#7A94B8" />
            Exchange
          </router-link>
        </li>
        <li class="nav-item" @click="onMobileNavigation">
          <router-link :to="{ name: 'History' }" active-class="active">
            <SvgIcon name="clock" stroke="#7A94B8" />
            History
          </router-link>
        </li>
        <li class="nav-item" @click="onMobileNavigation">
          <router-link :to="{ name: 'Settings' }" active-class="active">
            <SvgIcon name="settings" stroke="#7A94B8" />
            Settings
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import SvgIcon from "../SvgIcon";
import { mapGetters, mapMutations } from "vuex";
import { useWindowSize } from 'vue-window-size';

export default {
  components: {SvgIcon},

  setup(){
    const { width } = useWindowSize();
    return {
      windowWidth: width.value
    }
  },

  computed: {
    ...mapGetters([
      'SHOW_MOBILE_SIDEBAR'
    ]),

    displayClass(){
      if(this.SHOW_MOBILE_SIDEBAR && this.windowWidth <= 767)
        return 'd-block';
      return 'd-md-block';
    },
  },

  methods: {
    ...mapMutations([
      'SET_SHOW_MOBILE_SIDEBAR'
    ]),

    onMobileNavigation(){
      if(this.SHOW_MOBILE_SIDEBAR)
        this.SET_SHOW_MOBILE_SIDEBAR(false);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../scss/components/_sidebar.scss";
</style>
