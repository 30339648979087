<template>
  <section class="d-flex mx-auto justify-content-center">
    <div class="col-12 col-lg-4 auth--layout">
      <div class="d-flex justify-content-center logo text-center">
        <router-link :to="{ name: 'Home' }">
          <img src="/img/logo.svg" alt=""/>
        </router-link>
      </div>

      <slot/>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
@import "./src/scss/pages/auth.scss";
</style>