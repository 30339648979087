<template>
  <nav>
    <div class="justify-content-center justify-content-lg-between nav nav-tabs tab-header" :id="`nav-tab-${id}`" role="tablist">
      <button class="nav-link"
              :class="{ 'active' : activeIndex === key, [tab.class] : tab.class}"
              :id="`nav-${tab.id}-tab`"
              :data-id="key"
              data-bs-toggle="tab"
              :data-bs-target="`#nav-${tab.id}`"
              type="button"
              role="tab"
              :aria-controls="`nav-${tab.id}`"
              v-for="(tab, key) in tabs"
              :key="key"
      >
        <SvgIcon v-if="tab.icon" :name="tab.icon" :stroke="`${ activeIndex === key ? '#0062FF' : '#7A94B8'}`" class="me-2" />
        {{ tab.title }}
      </button>
    </div>
  </nav>
  <div class="tab-content" :id="`nav-tabContent-${id}`">
    <div class="tab-pane fade"
         :class="{ 'show active' : activeIndex === key }"
         :id="`nav-${tab.id}`"
         role="tabpanel"
         :aria-labelledby="`nav-${tab.id}-tab`"
         v-for="(tab, key) in tabs"
         :key="`tab-content-${key}`"
    >
      <slot :name="tab.id" />
    </div>
  </div>
</template>

<script>


import SvgIcon from "./SvgIcon";
export default {
  components: {SvgIcon},
  props: {
    tabs: {
      type: Array,
      required: true
    },
    activeTab: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeIndex: this.activeTab
    }
  },
  mounted() {
    let tab = document.querySelector(`#nav-tab-${this.id}`)
    tab.addEventListener('shown.bs.tab', (event) => {
      this.activeIndex = +event.target.getAttribute('data-id');
    })
  }
}
</script>

<style scoped>
</style>
