import RepositoryFactory from "../../api/RepositoryFactory";

export default {
  state: {
    giftcardSettings: null
  },

  mutations: {
    SET_GIFTCARD_SETTINGS(state, payload){
      state.giftcardSettings = payload;
    }
  },

  actions: {
    FETCH_GIFTCARD_SETTINGS(context){
      return RepositoryFactory.get('giftcardSettings').personal()
      .then(({ data }) => context.commit('SET_GIFTCARD_SETTINGS', data))
      .catch(err => console.log(err));
    }
  },

  getters: {
    GIFTCARD_SETTINGS(state){
      return state.giftcardSettings;
    }
  }
  
};
