import RepositoryFactory from "../../api/RepositoryFactory";

export default {
    state: {
        countries: []
    },
    mutations: {
        SET_COUNTRIES(state, payload) {
            state.countries = payload;
        }
    },
    actions: {
        FETCH_COUNTRIES(context) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('country').get().then(({ data }) => {
                    context.commit('SET_COUNTRIES', data)
                    resolve(data)
                }).catch(e => reject(e))
            })
        }
    },
    getters: {
        COUNTRIES: (state) => {
            return state.countries;
        }
    }
}
