<template>
  <div>
    <ax-currency-box
      @changeCurrency="onOrderCurrencySelected"
      :currencyId="form.order_amount_currency_id"
      :pm="true"
    />
    <div class="row mt-40px">
      <div :class="`${isPM ? 'col-12' : 'col-6'}`">
        <ax-input
          :error="v$.form.order_amount"
          :append-text="order_amount_currency.code"
          v-model="form.order_amount"
          type="number"
          placeholder="Amount"
          input-class="p-3"
          v-on:keyup="changeQuantity"
          v-on:click="changeQuantity"
          v-if="!isPM"
        />
        <ax-input
          :error="v$.form.order_amount_usd"
          append-icon="usd"
          v-model="form.order_amount_usd"
          type="number"
          placeholder="Amount"
          input-class="p-3"
          v-on:keyup="changePriceUSD"
          v-on:click="changePriceUSD"
          v-if="isPM"
        />
      </div>
      <template v-if="!isPM">
        <div class="col-6 position-relative">
          <span class="equal--symbol__middle">=</span>
          <ax-input
            :error="v$.form.order_amount_usd"
            append-text="USD"
            v-model="form.order_amount_usd"
            type="number"
            placeholder="Amount"
            input-class="p-3"
            v-on:keyup="changePriceUSD"
            v-on:click="changePriceUSD"
          />
        </div>
      </template>
    </div>

    <div class="col-12 mt-4">
      <ax-select-dropdown
        input-class="p-3"
        type="number"
        :label="`${sell ? 'You receive' : 'You Pay'}`"
        name="order_price"
        placeholder="Amount"
        label-class="pay-label"
        :options="price_currencies"
        @selected="onPriceCurrencySelected"
        v-model="form.order_price"
        id="order_price"
        :error="v$.form.order_price"
        v-on:keyup="changeOrderPrice"
        v-on:click="changeOrderPrice"
      />
    </div>

    <div class="col-12 mt-3 d-flex align-items-center calculation--box__text">
      <div class="w-auto">
        <SvgIcon name="alert-info" fill="#7A94B8" class="me-2" />
      </div>
      <ax-rate-carousel
        :currency="order_amount_currency.code"
        :currencies="prices"
      />
    </div>
    <ax-button class="w-100 p-3 text-uppercase mt-4" @click="onFormSubmit">{{
      sell ? "Sell" : "Buy"
    }}</ax-button>
  </div>
</template>

<script>
import { required, numeric } from "@vuelidate/validators";
import { positive, inAllowedRange } from "../../../validations";
import useVuelidate from "@vuelidate/core";
import AxCurrencyBox from "../../AxCurrencyBox";
import AxButton from "../../AxButton";
import AxInput from "../../AxInput";
import SvgIcon from "../../SvgIcon";
import AxSelectDropdown from "../../AxSelectDropdown";
import AxRateCarousel from "../../AxRateCarousel";
import AxAlert from "../../AxAlert";
import RepositoryFactory from "../../../api/RepositoryFactory";
import OrdersMixin from "../../../mixins/OrdersMixin";
import { mapGetters } from "vuex";
import _ from "loadsh";

export default {
  mixins: [OrdersMixin],

  components: {
    AxRateCarousel,
    AxSelectDropdown,
    SvgIcon,
    AxInput,
    AxButton,
    AxCurrencyBox,
    AxAlert,
  },

  props: {
    sell: {
      type: Boolean,
      default: false,
    },

    currency: {
      type: Object,
      default: null,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      form: {
        order_amount: {
          required,
          numeric,
          positive,
          inAllowedRange,
          $autoDirty: true,
        },

        order_amount_usd: {
          required,
          numeric,
          positive,
          $autoDirty: true,
        },

        order_price: {
          required,
          numeric,
          positive,
          $autoDirty: true,
        },
      },
    };
  },

  data() {
    return {
      prices: {},
      order_amount_currency: this.getCurrency("BTC"),
      price_currencies: [this.getCurrency("NGN"), this.getCurrency("TL")],
      error: null,
      form: {
        order_amount: 0,
        order_amount_currency_id: 1,
        order_amount_usd: 0,
        order_price: 0,
        order_price_currency_id: 4,
        status_id: 7,
      },
    };
  },

  methods: {
    onOrderCurrencySelected(newCurrency) {
      this.order_amount_currency = newCurrency;
      this.form.order_amount_currency_id = newCurrency.id;

      this.initPrices();
      this.settingPriceUSD();
      this.settingOrderPrice();
    },

    onPriceCurrencySelected(newCurrency) {
      this.order_price_currency = newCurrency;
      this.form.order_price_currency_id = newCurrency.id;
      this.settingOrderPrice();
    },

    onFormSubmit() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.error = "Value must be a positive number";
        return false;
      }

      RepositoryFactory.get("order")
        .createOrder(this.form)
        .then(({ data }) =>
          this.$router.push({
            name: this.sell ? "Sell" : "Buy",
            params: { orderId: data.id },
          })
        )
        .catch((err) => {
          this.$router.push({ name: "Login" })
        });
    },

    settingQuantity(sum, symbol) {
      this.form.order_amount = _.round(sum / this.prices[symbol] || 0, 6);
    },

    settingPriceUSD() {
      this.form.order_amount_usd = _.round(
        this.form.order_amount * this.prices.USD || 0
      );
    },

    settingOrderPrice() {
      const symbol = this.price_currencies.find(
        (item) => item.id === this.form.order_price_currency_id
      ).code;

      this.form.order_price = _.round(
        this.form.order_amount * this.prices[symbol] || 0
      );
    },

    changeQuantity() {
      this.settingPriceUSD();
      this.settingOrderPrice();
    },

    changePriceUSD() {
      this.settingQuantity(this.form.order_amount_usd, "USD");
      this.settingOrderPrice();
    },

    changeOrderPrice() {
      const symbol = this.price_currencies.find(
        (item) => item.id === this.form.order_price_currency_id
      ).code;

      this.settingQuantity(this.form.order_price, symbol);
      this.settingPriceUSD();
    },

    initPrices() {
      const prices = this.sell ? this.PRICES?.sell : this.PRICES?.buy;

      if (prices) {
        this.prices = prices.cryptocurrencies[this.order_amount_currency.code];
      }
    },
  },

  computed: {
    isPM() {
      return this.order_amount_currency.code === "PM";
    },

    sellId() {
      return this.sell ? "sell" : "";
    },

    changePrices() {
      return this.PRICES;
    },

    ...mapGetters(["PRICES"]),
  },

  watch: {
    changePrices() {
      this.initPrices();
      this.settingPriceUSD();
      this.settingOrderPrice();
    },
  },

  created() {
    if (this.sell) this.form.action_id = 2;
    else this.form.action_id = 1;

    this.initPrices();
  },
};
</script>

<style scoped lang="scss">
@import "../../../scss/components/home/home-calulcate";
</style>
