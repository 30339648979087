<template>
  <ax-idle 
    @idle="onIdle"
    :timeout="300"
  >
    <div>
      <ax-header fixed :auth="true" />
      <div class="container-fluid">
        <div class="row">
          <ax-sidebar />

          <main 
            class="col-md-9 ms-sm-auto col-lg-10 px-0 px-md-4 padding-top--header"
            v-if="loaded"
          >
            <div class="pt-5 pb-5 mb-3 px-2">
              <slot />
            </div>

            <div class="row--footer mt-5">
              <ax-footer />
            </div>
          </main>
        </div>
      </div>
    </div>
  </ax-idle>
</template>

<script>
import AxHeader from "../components/_partials/_header";
import AxFooter from "../components/_partials/_footer";
import SvgIcon from "../components/SvgIcon";
import AxSidebar from "../components/_partials/_sidebar";
import AxIdle from "../components/AxIdle";
import { mapActions } from "vuex";

export default {
  components: {
    AxSidebar, 
    AxHeader, 
    AxFooter,
    AxIdle,
    SvgIcon
  },

  data(){
    return {
      loaded: false
    }
  },

  methods: {
    ...mapActions([
      'FETCH_CURRENCYS', 
      'FETCH_ACTIONS',
      'FETCH_PRICES',
      'FETCH_GIFTCARDS'
    ]),

    onIdle(){
      this.$store.dispatch('LOGOUT');
      this.$router.push({ name: 'Login' });
    }
  },

  async mounted(){
    try {
      await this.FETCH_CURRENCYS();
      await this.FETCH_ACTIONS();
      await this.FETCH_PRICES();
      await this.FETCH_GIFTCARDS();
    }
    catch(err){
      console.log(err);
    }
    this.loaded = true;
  }

}
</script>

<style lang="scss">
@import "../scss/components/dashboard";
</style>
