import axios from "axios";
import store from "./../store"
import qs from 'qs'

const baseURL = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create({
    baseURL,
    transformRequest: [function (data) {
        return  qs.stringify(data)
    }]
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        store.dispatch("LOGOUT");
    }

    return Promise.reject(error);
});

axiosInstance.interceptors.request.use((config) => {

    const AUTH_TOKEN = localStorage.getItem('auth_token');

    if (AUTH_TOKEN) {
        config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance;
