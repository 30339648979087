import RepositoryFactory from "../../api/RepositoryFactory";

export default {
    state: {
        news: [],
        newsTotal: 0,
        currentNewsPage: 1
    },
    mutations: {
        SET_NEWS(state, data) {
            state.news = data.news;
        },
        SET_NEWS_TOTAL(state, data) {
          state.newsTotal = data.total;
        },
        SET_NEWS_PAGE(state, pageNum) {
          state.currentNewsPage = pageNum;
        },
        SET_FEATURES(state, features) {
            state.features = features;
        }
    },
    actions: {
        FETCH_HOME_NEWS({ commit, state }) {
            return new Promise((resolve, reject) => {
                const pagination = {
                    pageNum: state.currentNewsPage,
                    limit: 10
                };
                RepositoryFactory.get('news').all(pagination)
                .then(({ data }) => {
                    commit('SET_NEWS', data)
                    commit('SET_NEWS_TOTAL', data);
                    resolve()
                }).catch(error => reject(error))
            })
        },

        FETCH_HOME_FEATURES(context) {
            //TODO do api call
            context.commit('SET_FEATURES', [
                {
                    img: '/img/features/feature-1.png',
                    title: 'Rewards for Transacting',
                    description: 'ABonuses for each operation'
                },
                {
                    img: '/img/features/exchange.png',
                    title: 'Exchange Currencies',
                    description: 'Naira to TL and vice versa'
                },
                {
                    img: '/img/features/gift-card.png',
                    title: 'Sell Gift Cards',
                    description: 'And get paid instantly'
                },
                {
                    img: '/img/features/autobuy.png',
                    title: 'Auto Buy/Sell',
                    description: 'Coming Soon...'
                }
            ]);
        }
    },
    getters: {
        HOME_NEWS: (state) => {
            return state.news;
        },
        HOME_FEATURES: (state) => {
            return state.features;
        },
        HOME_NEWS_TOTAL: (state) => {
            return state.newsTotal;
        },
        HOME_CURRENT_NEWS_PAGE: (state) => {
            return state.currentNewsPage;
        }
    }
}
