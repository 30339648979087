import { mapActions } from "vuex";

export default {

  data(){
    return {
      loaded: false
    }
  },

  methods: {
    ...mapActions([
      'FETCH_CURRENCYS',
      'FETCH_ACTIONS'
    ])
  },

  mounted(){
    Promise.all([
      this.FETCH_CURRENCYS(),
      this.FETCH_ACTIONS()
    ])
    .then(() => this.loaded = true);
  }
}
