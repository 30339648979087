<template>
  <div>
    <slot/>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ax-idle',

  emits: ['idle'],

  props: {
    timeout: {
      type: Number,
      default: 10
    },
    events: {
      type: Array,
      default: ['touchstart', 'keypress', 'mousemove']
    }
  },

  data(){
    return {
      idleCheckInterval: null,
      lastEventMoment: moment()
    }
  },

  methods: {
    checkIdle(){
      if(moment().isAfter(moment(this.lastEventMoment).add(this.timeout, 'second')))
        this.$emit('idle')
    },

    addListeners(){
      for(let eventName of this.events)
        window.addEventListener(eventName, this.lastEventMomentUpdate);
    },

    lastEventMomentUpdate(){
      this.lastEventMoment = moment();
    }
  },

  mounted(){
    this.idleCheckInterval = setInterval(this.checkIdle, 1000);
    this.addListeners();
  }

}
</script>
