<template>
  <div v-if="loaded">
    <ax-header />
    <section class="container mt-5 px-0 px-md-5" >
      <slot />
    </section>
    <ax-footer />
  </div>
</template>

<script>
import AxHeader from "../components/_partials/_header";
import AxFooter from "../components/_partials/_footer";
import { mapActions } from "vuex";
import DataLoader from "../mixins/DataLoader";

export default {
  components: {
    AxHeader, 
    AxFooter,
  },
  mixins: [ DataLoader ],
}
</script>

<style scoped>
.container {
  max-width: 100%;
}

</style>
