import Repository from './../Repository';

const resource = '/news';

export default {
  all(pagination) {
    if(!pagination)
      return Repository.get(`${resource}`);
    const queryString = new URLSearchParams(pagination).toString();
    return Repository.get(`${resource}?${queryString}`);
  },

  getNews(id) {
    return Repository.get(`${resource}/${id}`);
  }
};
