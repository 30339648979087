import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {public:true}
  },
  {
    path: '/buttons',
    name: 'Buttons',
    component: () => import('../views/Buttons.vue'),
    meta: {public:true}

  },
  {
    path: '/icons',
    name: 'Icons',
    component: () => import('../views/Icons'),
    meta: {public:true}
  },
  {
    path: '/tooltips',
    name: 'Tooltips',
    component: () => import('../views/Tooltips'),
    meta: {public:true}
  },
  {
    path: '/switches',
    name: 'Switches',
    component: () => import('../views/Switches'),
    meta: {public:true}
  },
  {
    path: '/inputs',
    name: 'Inputs',
    component: () => import('../views/Inputs'),
    meta: {public:true}
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth/Login'),
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/verify',
    name: 'AuthVerify',
    component: () => import('../views/Auth/Verify'),
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Auth/Register'),
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/forgot-password',
    name: 'Forgot',
    component: () => import('../views/Auth/Forgot'),
    meta: {
      layout: 'auth',
      auth:false
    }
  },
  {
    path: '/forgot-verify',
    name: 'ForgotVerify',
    component: () => import('../views/Auth/ForgotVerify'),
    meta: {
      layout: 'auth',
      auth:false
    }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('../views/Auth/ChangePassword'),
    meta: {
      layout: 'auth',
      auth:false
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {public:true}
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue'),
    meta: {public:true}
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {public:true}
  },
  {
    path: '/terms-privacy',
    name: 'Terms',
    component: () => import('../views/Terms.vue'),
    meta: {public:true}
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue'),
    meta: {public:true}
  },
  {
    path: '/news/:id',
    name: 'NewsDetails',
    component: () => import('../views/NewsDetails.vue'),
    meta: {public:true}
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard/Index'),
    meta: {
      layout: 'user',
      auth:true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Dashboard/Settings/Index'),
    meta: {
      layout: 'user',
      auth: true
    }
  },
  {
    path: '/buy/:orderId?',
    name: 'Buy',
    component: () => import('../views/Dashboard/Buy/Index'),
    meta: {
      layout: 'user',
      auth: true
    }
  },
  {
    path: '/sell/:orderId?',
    name: 'Sell',
    component: () => import('../views/Dashboard/Sell/Index'),
    meta: {
      layout: 'user',
      auth: true
    }
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/Dashboard/History/Index'),
    meta: {
      layout: 'user',
      auth:true
    }
  },
  {
    path: '/exchange/:orderId?',
    name: 'Exchange',
    component: () => import('../views/Dashboard/Exchange/Index'),
    meta: {
      layout: 'user',
      auth:true
    }
  },
  {
    path: '/order/success',
    name: 'orderSuccess',
    component: () => import('../views/Dashboard/Order/Success'),
    meta: {
      layout: 'order',
      auth:true
    }
  },
  {
    path: '/pm/success',
    name: 'PMSuccess',
    component: () => import('../components/AxPmSuccess.vue'),
    meta: {
      layout: 'user',
      auth:true
    }
  },
  {
    path: '/pm/fail',
    name: 'PMFail',
    component: () => import('../components/AxPmFail.vue'),
    meta: {
      layout: 'user',
      auth:true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

//router.beforeRouteEnter((to, from, next) => {
//  console.log('before route enter');
//  if (Object.keys(store.state.currencys).length === 0) {
//    store.dispatch('FETCH_CURRENCYS')
//      .then(() => {
//        console.log(store.state.currencys);
//        next();
//      });
//  }
//});

router.beforeEach((to, from, next) => {
  const isAuthRoute = to.meta.auth;
  const isPublicRoute = to.meta.public;
  const IS_AUTHENTICATED = localStorage.getItem('auth_token');

  if(isPublicRoute) 
    return next();
  else if(!isAuthRoute && IS_AUTHENTICATED) {
    return next({ name: 'Home' })
  } else if(isAuthRoute && !IS_AUTHENTICATED) {
    return next({ name: 'Login' })
  }
  next();
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
});

export default router
