import Repository from './../Repository';

const resource = '/settings';

export default {
  getNotificationSettings(){
    return Repository.get(`${resource}/getNotificationSettings`);
  },

  getTwoFactorAuths() {
      return Repository.get(`${resource}/getTwoFactorAuths`);
  },

  updateSetting(payload) {
      return Repository.post(`${resource}/update`, payload);
  },

  updateInTwoFactor(payload) {
      return Repository.post(`${resource}/updateInTwoFactor`, payload)
  }
};
