import RepositoryFactory from '../api/RepositoryFactory';

export default {

  data(){
    return({
      order: null
    });
  },

  methods: {

    loadOrder(orderId){
      RepositoryFactory.get('order').getOrder(orderId)
      .then(({data}) => this.order = data) 
      .catch(err => console.log)
    },

    onDraftSubmit(orderDraft){
      this.$router.push({ name: 'Buy', params: { orderId: orderDraft.id } });
    },

    formApiMethod(){
      const orderRepo = RepositoryFactory.get('order');
      const { orderId } = this.$route.params;
      if(orderId)
        return orderRepo.updateOrder(orderId, this.form);
      return orderRepo.createOrder(this.form); 
    },

    getCurrency(code){
      return this.$store.getters.CURRENCYS[code];
    }

  },

  watch: {
    orderId(newOrderId){
      this.loadOrder(newOrderId);
    }
  },

  mounted(){
    if(this.$route.params.orderId)
      this.loadOrder(this.$route.params.orderId);
  }

};
