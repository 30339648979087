export default {

  state: {
    showMobileSidebar: false
  },

  mutations: {
    SET_SHOW_MOBILE_SIDEBAR(state, showMobileSidebar){
      state.showMobileSidebar = showMobileSidebar;
    }
  },

  actions: {
    TOGGLE_MOBILE_SIDEBAR({ state, commit }){
      commit('SET_SHOW_MOBILE_SIDEBAR', !state.showMobileSidebar);
    }
  },


  getters: {
    SHOW_MOBILE_SIDEBAR (state) {
      return state.showMobileSidebar;
    }
  }
}
