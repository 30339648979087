<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import AxButton from "./components/AxButton";
import RepositoryFactory from "./api/RepositoryFactory";

export default {
  components: {
    AxButton,
  },

  computed: {
    layout() {
      const layoutName = this.$route.meta.layout || 'default';

      return `${layoutName}-layout`;
    }
  }
}
</script>

<style lang="scss">

</style>
