import Repository from './../Repository';

const getPaginationQuery = pagination => {
  if(!pagination)
    return '';
  return new URLSearchParams(pagination).toString();
};

const getFilterQuery = filter => {
  if(!filter || Object.keys(filter) === 0)
    return '';
  return '&filter=' + encodeURI(JSON.stringify(filter));
};

export default {
  
  all(pagination, filter){
    const paginationQuery = getPaginationQuery(pagination);
    const filterQuery = getFilterQuery(filter);
    return Repository.get(`/history?${paginationQuery}&${filterQuery}`);
  },

};
