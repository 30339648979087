<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="col-12 mt-4">
      <ax-select-dropdown
        input-class="p-3"
        label="You Pay"
        placeholder="Enter Amount"
        label-class="pay-label"
        :options="exchange_currencies"
        @selected="onOrderCurrencySelected"
        :active="order_amount_currency"
        v-model="form.order_amount"
        id="order_amount"
        type="number"
        :error="v$.form.order_amount"
        v-on:keyup="changeQuantity"
        v-on:click="changeQuantity"
      />
    </div>
    <div class="col-12 mt-40px">
      <ax-select-dropdown
        input-class="p-3"
        label="You Receive"
        label-class="pay-label"
        :options="exchange_currencies"
        @selected="onPriceCurrencySelected"
        :active="order_price_currency"
        v-model="form.order_price"
        id="order_price"
        type="number"
        :error="v$.form.order_price"
        v-on:keyup="changeOrderPrice"
        v-on:click="changeOrderPrice"
      />
    </div>
    <div
      class="col-12 mt-40px d-flex align-items-center calculation--box__text"
    >
      <SvgIcon name="alert-info" fill="#7A94B8" class="me-2" />
      <ax-rate-carousel
        :currency="order_amount_currency.code"
        :currencies="prices"
      />
    </div>

    <ax-button class="w-100 p-3 text-uppercase mt-40px" @click="onFormSubmit"
      >Exchange</ax-button
    >
  </div>
</template>

<script>
import { required, numeric } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { positive, inAllowedRangeTlNgn } from "../../../validations";
import AxInput from "../../AxInput";
import SvgIcon from "../../SvgIcon";
import AxButton from "../../AxButton";
import AxSelectDropdown from "../../AxSelectDropdown";
import OrdersMixin from "../../../mixins/OrdersMixin";
import AxRateCarousel from "../../AxRateCarousel";
import { mapGetters } from "vuex";
import _ from "loadsh";

export default {
  mixins: [OrdersMixin],

  components: { AxSelectDropdown, AxButton, SvgIcon, AxInput, AxRateCarousel },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      form: {
        order_amount: {
          required,
          numeric,
          positive,
          inAllowedRangeTlNgn,
          $autoDirty: true,
        },

        order_price: {
          required,
          numeric,
          positive,
          $autoDirty: true,
        },
      },
    };
  },

  data() {
    return {
      prices: {},
      order_amount_currency: this.getCurrency("NGN"),
      order_price_currency: this.getCurrency("TL"),
      exchange_currencies: [this.getCurrency("NGN"), this.getCurrency("TL")],
      form: {
        action_id: 3,
        order_amount: 0,
        order_amount_currency_id: this.getCurrency("NGN").id,
        order_price: 0,
        order_price_currency_id: this.getCurrency("TL").id,
      },
    };
  },

  methods: {
    getOppositeCurrency(newCurrency) {
      const oppositeArray = this.exchange_currencies.filter(
        (currency) => currency.code !== newCurrency.code
      );
      return oppositeArray[0];
    },

    onOrderCurrencySelected(newCurrency) {
      this.order_amount_currency = newCurrency;
      this.form.order_amount_currency_id = newCurrency.id;
      const oppositeCurrency = this.getOppositeCurrency(newCurrency);
      this.order_price_currency = oppositeCurrency;
      this.order_price_currency_id = oppositeCurrency.id;

      this.initPrices();
    },

    onPriceCurrencySelected(newCurrency) {
      this.order_price_currency = newCurrency;
      this.form.order_price_currency_id = newCurrency.id;
      const oppositeCurrency = this.getOppositeCurrency(newCurrency);
      this.order_amount_currency = oppositeCurrency;
      this.order_amount_currency_id = oppositeCurrency.id;

      this.initPrices();
    },

    onFormSubmit() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.error = "Value must be a positive number";
        return false;
      }
      this.formApiMethod()
        .then(({ data }) => {
          this.$router.push({
            name: "Exchange",
            params: { orderId: data.id },
          });
        })
        .catch((err) => {
          this.$router.push({ name: "Login" })
        });
    },

    settingQuantity() {
      const symbol = this.exchange_currencies.find(
        (item) => item.id === this.form.order_price_currency_id
      ).code;

      this.form.order_amount = _.round(
        this.form.order_price / this.prices[symbol] || 0,
        2
      );
    },

    settingOrderPrice() {
      const price_currency = this.exchange_currencies.find(
        (item) => item.id === this.form.order_price_currency_id
      ).code;

      const amount_currency = this.exchange_currencies.find(
        (item) => item.id === this.form.order_amount_currency_id
      ).code;

      this.form.order_price = _.round(
        this.form.order_amount * this.prices[price_currency] || 0,
        2
      );
    },

    changeQuantity() {
      this.settingOrderPrice();
    },

    changeOrderPrice() {
      this.settingQuantity();
    },

    initPrices() {
      const amount_currency = this.exchange_currencies.find(
        (item) => item.id === this.form.order_amount_currency_id
      ).code;

      const prices =
        amount_currency === "NGN" ? this.PRICES?.buy : this.PRICES?.sell;

      if (prices) {
        this.prices = prices.currencies[amount_currency];
      }
    },
  },

  computed: {
    ...mapGetters(["PRICES"]),

    changePrices() {
      return this.PRICES;
    },
  },

  watch: {
    changePrices() {
      this.initPrices();
    },
  },

  created() {
    this.initPrices();
  },
};
</script>

<style scoped lang="scss">
@import "../../../scss/components/home/home-calulcate";
</style>
