import RepositoryFactory from "../../api/RepositoryFactory";


export default {
    state: {
        two_factor_auths: {},
        setting: {},
    },
    mutations: {
        SET_TWO_FACTOR_AUTHS(state, data) {
            state.two_factor_auths = data;
        },
        SET_SETTING(state, data) {
            state.setting = Object.assign(state.setting, data)
        },
        SET_NOTIFICATION_SETTINGS(state, data){
            if(!state.setting.notifications)
              state.setting.notifications = {};
            state.setting = Object.assign(state.setting.notifications, data)
        },
        SET_NOTIFICATION_SETTING(state, data){
            const { settingName, value } = data;
            state.setting.notifications[settingName] = value;
        } 
    },
    actions: {
        FETCH_TWO_FACTOR_AUTHS({ commit }) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('setting').getTwoFactorAuths().then(({ data }) => {
                    commit('SET_TWO_FACTOR_AUTHS', data)
                    resolve()
                }).catch(error => reject(error))
            })
        },

        FETCH_NOTIFICATION_SETTINGS({commit}) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('setting').getNotificationSettings().then(({data}) => {
                    commit('SET_SETTING', {notifications: data})
                    resolve()
                }).catch(error => reject(error))
            })
        },

        UPDATE_SETTING({commit, getters}) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('setting').updateSetting(getters.SETTING).then(({ data, status }) => {
                    if (status === 201) {
                        commit('SET_TWO_FACTOR_AUTHS', data)
                    }
                    resolve(status)
                }).catch(error => reject(error))
            })
        },

        UPDATE_IN_TWO_FACTOR({commit}, payload) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('setting').updateInTwoFactor(payload).then(({ data, status }) => {
                    commit('SET_TWO_FACTOR_AUTHS', payload.security)
                    resolve(status)
                }).catch(error => reject(error))
            })
        },
    },
    getters: {
        TWO_FACTOR_AUTHS: (state) => {
            return state.two_factor_auths;
        },
        SETTING: state => {
            return state.setting
        },
        NOTIFICATION_SETTINGS: state => {
          console.log(state);
          return state.setting.notifications;
        }
    }
}
