export const buildLabledList = (list, labelKey) => {
  return list.reduce((result, item) => {
    const resultKey = item[labelKey];
    result[resultKey] = item;
    return result;
  }, {});
};

export const onlyNumber = $event => {
  const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46)
    $event.preventDefault();
};
