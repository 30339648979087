<template>
  <component :is="to ? 'router-link' : 'button'"
             :class="`btn btn-${variant} ${disabled ? 'disabled' : ''} ${hasIcon ? 'd-flex align-items-center' : ''}`"
             :disabled="disabled"
             :to="to ? to : null"
             :type="type"
  >
    <template v-if="prependIcon">
      <SvgIcon :name="prependIcon" :stroke="iconStroke" class="me-2"/>
    </template>
    <slot/>
    <template v-if="icon">
      <SvgIcon :name="icon" :stroke="iconStroke"/>
    </template>
    <template v-if="appendIcon">
      <SvgIcon :name="appendIcon" :stroke="iconStroke" class="ms-2"/>
    </template>
  </component>
</template>

<script>
import SvgIcon from "./SvgIcon";

export default {
  components: {SvgIcon},
  props: {
    variant: {
      type: String,
      default: 'blue'
    },
    prependIcon: {
      type: String,
      default: ''
    },
    appendIcon: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconStroke: {
      type: String,
      default: '#fff'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  computed: {
    hasIcon() {
      return this.icon || this.prependIcon || this.appendIcon;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../scss/components/button";
</style>