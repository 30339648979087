import RepositoryFactory from "../../api/RepositoryFactory";

export default {
    state: {
        user_logs: [],
        user_profile: {},
        user_crypto_addresses: [],
        password_verification: true
    },
    mutations: {
        SET_USER_LOGS(state, logs) {
            state.user_logs = logs;
        },
        SET_USER_PROFILE(state, profile) {
            state.user_profile = profile;
        },
        SET_PASSWORD_VERIFICATION(state, status) {
            state.password_verification = status;
        },
        SET_USER_CRYPTO_ADDRESSES(state, crypto_addresses) {
            state.user_crypto_addresses = crypto_addresses;
        },
    },
    actions: {
        FETCH_USER_LOGS({ commit }) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('user').getUserLogs().then(({ data }) => {
                    commit('SET_USER_LOGS', data)
                    resolve()
                }).catch(error => reject(error))
            })
        },
        FETCH_USER_PROFILE({ commit }) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('user').getUserProfile().then(({ data }) => {
                    commit('SET_USER_PROFILE', data)
                    resolve()
                }).catch(error => reject(error))
            })
        },
        FETCH_USER_CRYPTO_ADDRESSES({ commit }) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('user').getUserCryptoAddresses().then(({ data }) => {
                    commit('SET_USER_CRYPTO_ADDRESSES', data)
                    resolve()
                }).catch(error => reject(error))
            })
        },
    },
    getters: {
        USER_LOGS: (state) => {
            return state.user_logs
        },
        USER_PROFILE: (state) => {
            return state.user_profile
        },
        PASSWORD_VERIFICATION: (state) => {
            return state.password_verification
        },
        USER_CRYPTO_ADDRESSES: (state) => {
            return state.user_crypto_addresses
        },
    }
}
