import Repository from './../Repository';

const resource = '/pm';

export default {

  nameLookup(pmId) {
    return Repository.get(`${resource}/nameLookup/${pmId}`);
  }
};
