<template>
  <section class="h-100 home--particles__box">
    <Particles
        id="particles"
        class="w-100 h-100"
        :options="options"
    />

    <div class="home--slider__items" :style="{ transform: `translateY(-${slider.position}px)`}">
      <div class="home--slider__item text-uppercase">
        <p>Buy</p>
        <span>Crypto</span>
      </div>
      <div class="home--slider__item text-uppercase">
        <p>Sell</p>
        <span>Crypto</span>
      </div>
      <div class="home--slider__item text-uppercase">
        <p>Exchange</p>
        <span>Money</span>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  data() {
    return {
      options: {
        background: {
          color: {
            value: '#94b2cd'
          }
        },
        fpsLimit: 60,
        interactivity: {
          detectsOn: 'canvas',
          events: {
            onClick: {
              enable: false,
            },
            onHover: {
              enable: false,
            },
            resize: false
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40
            },
          }
        },
        particles: {
          color: {
            value: '#ffffff'
          },
          links: {
            color: '#ffffff',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1
          },
          collisions: {
            enable: true
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'out',
            random: false,
            speed: 2,
            straight: true
          },
          number: {
            density: {
              enable: true,
              value_area: 500
            },
            value: 120
          },
          opacity: {
            value: 0.5
          },
          shape: {
            type: 'circle'
          },
          size: {
            random: true,
            value: 6
          }
        },
        detectRetina: true
      },
      slider: {
        index: 1,
        position: 0,
        height: 500,
        maxCount: 3
      },
    }
  },
  created() {
    this.initSlider();
  },
  methods: {
    initSlider() {
      setInterval(() => {
        if (this.slider.index === this.slider.maxCount) {
          this.slider.position = 0;
          this.slider.index = 1;
          return;
        }

        this.slider.position = this.slider.index * this.slider.height;
        this.slider.index++;
      }, 5000)
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/components/home/home-particles";
</style>
