import Repository from './../Repository';

export default {
  createOrder(orderForm){
    return Repository.post('/orders/create', orderForm);
  },

  orders(pagination){
    if(!pagination)
      return Repository.get('/orders');
    const queryString = new URLSearchParams(pagination).toString();
    return Repository.get(`/orders?${queryString}`);
  },

  getOrder(orderId){
    return Repository.get('/orders/' + orderId);
  },

  updateOrder(orderId, updates){
    return Repository.post('/orders/update/' + orderId, updates);
  },

  deleteOrder(orderId){
    return Repository.post('/orders/update/' + orderId, { status_id: 6 });
  },

  paymentSent(orderId){
    return Repository.post('/orders/update/' + orderId, { status_id: 3 });
  },

  reward(){
    return Repository.get('/orders/reward');
  }
};
