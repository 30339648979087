<template>
  <div>
  <label
    :for="`input-${id}`"
    :class="`form-label ${labelClass}`"
    v-if="label"
    >{{ label }}</label
  >
  <div :class="`${isAppend ? 'input-group input--append__style' : ''}`">
    <input
      :type="type"
      :min="min"
      :class="`form-control ax--input ${inputClass} ${
        error.$error ? 'is-invalid' : ''
      }`"
      :disabled="disabled"
      :name="name"
      :id="`input_${id}`"
      :aria-describedby="`hint_${id}`"
      @input="$emit('update:modelValue', $event.target.value)"
      :value="modelValue"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
    />
    <div :id="`hint_${id}`" class="form-text" v-if="hint">{{ hint }}</div>

    <span class="input-group-text" :id="`hint_${id}`" v-if="appendText">{{
      appendText
    }}</span>
    <span class="input-group-text" :id="`hint_${id}`" v-if="appendIcon">
      <SvgIcon
        :name="appendIcon"
        :stroke="appendIconColor"
        @click="$emit('iconClickCallback')"
        :class="iconClass"
      />
    </span>
    <div class="invalid-feedback" v-if="error.$error">
      {{ error.$errors[0].$message }}
    </div>
  </div>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon";
import { onlyNumber } from "../utils";

export default {
  components: { SvgIcon },

  props: {
    min: {
      type: Number,
      default: 0,
    },

    placeholder: {
      type: String,
      default: "",
    },

    name: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "text",
    },

    id: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    hint: {
      type: String,
      default: "",
    },

    autocomplete: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    appendText: {
      type: String,
      default: "",
    },

    appendIcon: {
      type: String,
      default: "",
    },

    appendIconColor: {
      type: String,
      default: "",
    },

    iconClass: {
      type: String,
      default: "",
    },

    inputClass: {
      type: String,
      default: "",
    },

    labelClass: {
      type: String,
      default: "",
    },

    modelValue: [Number, String],

    error: {
      type: Object,
      default: {},
    },
  },

  emits: ["update:modelValue"],

  computed: {
    isAppend() {
      return this.appendText || this.appendIcon;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/components/input";
</style>
