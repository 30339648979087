import RepositoryFactory from "../../api/RepositoryFactory";

export default {
    state: {
      orders: []
    },

    mutations: {
      ADD_ORDER(state, order){
        state.orders.push(order);
      }
    },

    actions: {
      CREATE_ORDER({commit}, orderForm){
        RepositoryFactory.get('order')
        .createOrder(orderForm)
        .then(res => console.log);
      },
    },

    getters: {
      ORDERS(state){
        return state.orders;
      }
    }
}
