<template>
  <footer>
    <div class="text-center">
      <router-link :to="{ name: 'Home' }">
        <img src="/img/logo.svg" alt="logo" />
      </router-link>
    </div>

    <nav class="mt-5 footer--nav__links">
      <ul class="d-flex justify-content-center list-unstyled">
        <li>
          <router-link :to="{ name: 'About' }">About</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'News' }">News</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'FAQ' }" class="text-uppercase">Faq</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Contact' }">Contact</router-link>
        </li>
      </ul>
    </nav>

    <hr class="footer--divider" />

    <h2 class="footer--info">Alfam, eastern Mediterranean university, Magusa, TRNC</h2>
    
    <ul class="footer--social__icons list-unstyled d-flex justify-content-center">
      <li>
        <a href="https://wa.me/2348141238323" target="_blank">
          <SvgIcon name="whatsapp" width="24" height="24" />
        </a>
      </li>
      <li class="ms-5">
        <a href="https://www.instagram.com/acryptox" target="_blank">
          <img src="/img/icons/instagram.svg" alt="instagram" width="20" height="20"/>
        </a>
      </li>
      <li class="ms-5">
        <a href="#">
          <SvgIcon name="twitter" width="24" height="24" />
        </a>
      </li>
      <li class="ms-5">
        <a href="https://www.youtube.com/channel/UCs-0hTBAqItYHIAvnnlXaaA" target="_blank">
          <SvgIcon name="youtube" width="24" height="24" />
        </a>
      </li>
      <li class="ms-5">
        <a href="#">
          <SvgIcon name="discord" width="24" height="24" />
        </a>
      </li>
      <li class="ms-5">
        <a href="https://t.me/acryptox1" target="_blank">
          <SvgIcon name="telegram" width="24" height="24" />
        </a>
      </li>
    </ul>

    <p class="footer--copyright">
      &copy; {{ year }}
      <router-link :to="{ name: 'Terms' }">
        Privacy - Terms
      </router-link>
    </p>
  </footer>
</template>

<script>
import SvgIcon from "../SvgIcon";
export default {
  components: {SvgIcon},
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/scss/components/footer";
</style>
