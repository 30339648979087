import Repository from '../Repository';

const resource = '/bankaccounts';

export default {
    getAccountTypes() {
        return Repository.get(`${resource}/types`);
    },
    getAccounts() {
        return Repository.get(`${resource}`);
    },
    createAccounts(account) {
        return Repository.post(`${resource}/create`, account);
    }
};
