import RepositoryFactory from "../../api/RepositoryFactory";
import { buildLabledList } from "../../utils";

export default {
    state: {
        actions: {}
    },

    mutations: {
        SET_ACTIONS(state, payload) {
          state.actions = buildLabledList(payload, 'name');
        }
    },

    actions: {
        FETCH_ACTIONS(context) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('action').all()
                .then(({ data }) => {
                    context.commit('SET_ACTIONS', data)
                    resolve(data)
                })
                .catch(e => reject(e))
            })
        }
    },

    getters: {
        ACTIONS: (state) => {
            return state.actions;
        }
    }
}
