import RepositoryFactory from "../../api/RepositoryFactory";

export default {
    state: {
        prices: {},
    },

    mutations: {
        SET_PRICES(state, data) {
            state.prices = data;
        },
    },

    actions: {
        FETCH_PRICES({ commit }) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('price').getPrice().then(({ data }) => {
                    commit('SET_PRICES', data)
                    resolve()
                }).catch(error => reject(error))
            })
        },
    },

    getters: {
        PRICES: (state) => {
            return state.prices;
        },
    }
}
