<template>
  <section class="currency--box">
    <section class="row currency--box__items">
      <div :class="`col-${colSize} currency--box__item ${ activeIndex === 0 ? 'active' : '' } flex-grow-1`"  @click="onCurrencyChanged(0, 'BTC')">
        <section class="d-flex justify-content-center">
          <div>
            <SvgIcon name="bitcoin" width="24" height="24" class="me-2"/>
          </div>
          <span class="d-none d-lg-block">Bitcoin</span>
          <span class="d-lg-none d-sm-block">BTC</span>
        </section>
      </div>
      <div :class="`col-${colSize} currency--box__item ${ activeIndex === 1 ? 'active' : '' } flex-grow-1`"  @click="onCurrencyChanged(1, 'ETH')">
        <section class="d-flex justify-content-center">
          <div>
            <SvgIcon name="crypto" width="24" height="24" class="me-2"/>
          </div>
          <span class="d-none d-lg-block">Etherium</span>
          <span class="d-lg-none d-sm-block">ETH</span>
        </section>
      </div>
      <div 
        :class="`col-${colSize} currency--box__item ${ activeIndex === 2 ? 'active' : '' } flex-grow-1`"  
        @click="onCurrencyChanged(2, 'PM')"
        v-if="pm"
      >
        <section class="d-flex justify-content-center">
          <div>
            <SvgIcon name="pm" width="24" height="24" class="me-2"/>
          </div>
          <span>PM</span>
        </section>
      </div>
      <div v-if="gift" :class="`col-${colSize} currency--box__item ${ activeIndex === 3 ? 'active' : '' } flex-grow-1`"  @click="onCurrencyChanged(3, 'GIFT')">
        <section class="d-flex justify-content-center">
          <div>
            <SvgIcon name="gift-card" width="24" height="24" class="me-2"/>
          </div>
          <span>Gift&nbsp;Card</span>
        </section>
      </div>
    </section>
  </section>
</template>

<script>
import SvgIcon from "./SvgIcon";

export default {
  emits: [ 'changeCurrency' ],

  props: {
    gift:{
      type: Boolean,
      default: false
    },

    pm: {
      type: Boolean,
      default: false
    },

    currencyId: {
      type: Number,
      required: true
    }
  },

  components: {SvgIcon},

  data() {
    return {
      activeIndex: 0,
    }
  },

  methods: {
    onCurrencyChanged(activeIndex, currencyCode){
      this.activeIndex = activeIndex;
      const currency = this.getCurrency(currencyCode);
      this.$emit('changeCurrency', currency);
    },

    getCurrency(code){
      return this.$store.getters.CURRENCYS[code];
    },

    setCurrency(currencyId){
      if(!currencyId)
        return;
      if(currencyId === 7)
        this.activeIndex = 3;
      else
        this.activeIndex = currencyId - 1;
    }
  },

  computed: {
   colSize() {
     let blocksNum;
     if(this.gift)
       blocksNum = 4;
     else
       blocksNum = 3;
     return 12/blocksNum;
   }
  },

  watch: {
    currencyId(newCurrencyId){
      this.setCurrency(newCurrencyId);
    }
  },

  mounted(){
    this.setCurrency(this.currencyId);
  }
}

</script>

<style scoped lang="scss">
@import "../scss/components/_currency-box.scss";
</style>
