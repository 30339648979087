<template>
  <section class="news--item">
    <router-link :to="{ name: 'NewsDetails', params: { id: data.id } }">
      <div class="news-img-container">
        <img :src="path(data.img)" alt="" class="news-img" />
      </div>
      <div class="news--info d-flex flex-column">
        <p class="news--info__date">{{ data.date }}</p>
        <h2 class="news--info__title">{{ data.title }}</h2>
        <div class="news--info__description" v-html="data.description"></div>
        <ax-button
          variant="light"
          :to="{ name: 'NewsDetails', params: { id: data.id } }"
          append-icon="angle-right"
          icon-stroke="#7A94B8"
          class="align-self-end pe-4 ps-3 mb-4 mb-md-0"
        >
          Read more
        </ax-button>
      </div>
    </router-link>
  </section>
</template>

<script>
import AxButton from "../AxButton";

export default {
  components: { AxButton },
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      path: (imgName) => {
        const baseUrl = process.env.VUE_APP_API_URL;
        return `${baseUrl}/static/${imgName}`;
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import "./src/scss/components/home/home-news";
</style>
