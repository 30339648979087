<template>
  <swiper
    ref="swiper"
    class="ms-1"
    :autoplay="autoplayOptions"
    :allowTouchMove="false"
    :resistance="false"
  >
    <swiper-slide class="w-100 color-gray-600" v-for="value in getPrices()" :key="value.code">
      <div class="rate-item">
        {{ currency + " " + 1 + " = " + value.value + " " + value.code }}
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";

SwiperCore.use([Autoplay]);
export default {
  components: { Swiper, SwiperSlide },
  props: {
    currency: {
      type: String,
      default: "BTC",
    },
    currencies: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      autoplayOptions: {
        delay: 5000,
      },
    };
  },
  methods: {
    getPrices() {
      const prices = [
        ...Object.entries(this.currencies).map((item) => ({
          code: item[0],
          value: item[1],
        })),
      ];
      return prices;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/scss/components/rate_carousel";
</style>
