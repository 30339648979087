import RepositoryFactory from "../../api/RepositoryFactory";

export default {
    state: {
        token: localStorage.getItem('auth_token') ? localStorage.getItem('auth_token') : null,
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        login_token: null,

        old_email: localStorage.old_email || '',
        email_verified: localStorage.email_verified || false,

        register_data: {
            username: null,
            email: null,
            first_name: null,
            last_name: null,
            phone: null,
            country_id: null,
            password: null,
            password_confirmation: null,
            registration_code: localStorage.registration_code || '',
        },

        two_factory: {
            two_factory_required: null,
            email_auth: null,
            sms_auth: null,
            username_email: null,
            password: null,
            phone: null,
            email: null,
        },

        forgot_data: {
            username_email: '',
            email: '',
            phone: ''
        },
        forgot_token: '',
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('auth_token', token);
        },
        SET_USER(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        REMOVE_TOKEN(state) {
            state.token = null;
            localStorage.removeItem('auth_token');
        },
        REMOVE_USER(state) {
            state.user = null;
            localStorage.removeItem('user');
        },
        SET_REGISTER_DATA(state, form) {
            state.register_data = {...state.register_data, ...form}
        },
        SET_OLD_EMAIL(state, email) {
            state.old_email = email
            localStorage.old_email = email
        },
        SET_EMAIL_VERIFIED(state, email_verified) {
            state.email_verified = email_verified
            localStorage.email_verified = email_verified
        },
        REMOVE_EMAIL_VERIFIED(state) {
            state.email_verified = null
            localStorage.removeItem('email_verified')
        },
        SET_REGISTRATION_CODE(state, code) {
            state.register_data.registration_code = code
            localStorage.registration_code = code
        },
        SET_TWO_FACTORY(state, data) {
            state.two_factory = data
        },
        SET_FORGOT_DATA(state, data){
            state.forgot_data = data
        },
        SET_FORGOT_TOKEN(state, data){
            state.forgot_token = data
        }
    },
    actions: {
        LOGIN({commit}, payload) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').login(payload).then(({data, status}) => {
                    if (status === 201) {
                        commit('SET_TWO_FACTORY', {...data, ...payload})
                    } else {
                        commit("SET_TOKEN", data.token);
                        commit("SET_USER", data.user)
                    }
                    resolve();
                }).catch((err) => reject(err));
            })
        },
        TWO_FACTORY_CHECK({commit}, payload) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').twoFactory(payload).then(({data}) => {
                    commit("SET_TOKEN", data.token);
                    commit("SET_USER", data.user)
                    resolve();
                }).catch((err) => reject(err));
            })
        },
        SET_REGISTER_DATA({commit}, form) {
            commit('SET_REGISTER_DATA', form)
        },
        CHECK_UNIQUE({commit}, payload) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').checkUnique(payload).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        REGISTRATION_CODE({commit}, payload) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').registrationCode(payload).then((response) => {
                    commit('SET_REGISTRATION_CODE', response.data.code)
                    commit('SET_OLD_EMAIL', payload.email)
                    commit('REMOVE_EMAIL_VERIFIED')
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        VERIFICATION_EMAIL({commit}, payload) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').verificationEmail(payload).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        EMAIL_VERIFY({commit}, payload) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').emailVerify(payload).then((response) => {
                    commit('SET_EMAIL_VERIFIED', true)
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        VERIFICATION_PHONE({commit}, payload) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').verificationPhone(payload).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        PHONE_VERIFY({commit}, payload) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').phoneVerify(payload).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        INFO_COMPLETE({commit}, payload) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').infoComplete(payload).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        LOGOUT({commit}) {
            return new Promise((resolve, reject) => {
                commit("REMOVE_TOKEN");
                commit("REMOVE_USER");
                resolve();
            })
        },
        SEND_CODE_AGAIN({commit}, payload) {
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').sendCodeAgain(payload).then(response => {
                    resolve(response)
                }).catch(e => {
                    reject(e)
                })
            })
        },
        FORGOT_PASSWORD({commit}, payload){
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').forgotPassword(payload).then(response => {
                    commit('SET_FORGOT_DATA', {...payload,...response.data})
                    resolve(response)
                }).catch(e => {
                    reject(e)
                })
            })
        },
        FORGOT_PASSWORD_VERIFY({commit,getters}, payload){
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').forgotPasswordVerify(payload).then(response => {
                    commit('SET_FORGOT_TOKEN', response.data.forgot_token)
                    resolve(response)
                }).catch(e => {
                    reject(e)
                })
            })
        },
        CHANGE_PASSWORD(context, payload){
            return new Promise((resolve, reject) => {
                RepositoryFactory.get('auth').changePassword(payload).then(response => {
                    resolve(response)
                }).catch(e => {
                    reject(e)
                })
            })
        }
    },
    getters: {
        REGISTER_DATA: state => {
            return state.register_data
        },
        OLD_EMAIL: state => {
            return state.old_email
        },
        EMAIL_VERIFIED: state => {
            return state.email_verified
        },
        TWO_FACTORY: state => {
            return state.two_factory
        },
        AUTH_USER(state) {
            return state.user;
        },
        AUTH_TOKEN(state) {
            return state.token;
        },
        IS_AUTHENTICATED(state) {
            return !!state.token;
        },
        FORGOT_DATA(state) {
            return state.forgot_data
        },
        FORGOT_TOKEN(state){
            return state.forgot_token
        }
    }
}
