import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { svgSpritePlugin } from 'vue-svg-sprite'
import Particles from "particles.vue3";
import "./scss/main.scss";

require('./lib/bootstrap')

import DefaultLayout from "./layouts/DefaultLayout";
import UserLayout from "./layouts/UserLayout";
import AuthLayout from "./layouts/AuthLayout";
import OrderLayout from "./layouts/OrderLayout";
import firebaseMessaging from './firebase';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(Particles)
    .component('default-layout', DefaultLayout)
    .component('user-layout', UserLayout)
    .component('auth-layout', AuthLayout)
    .component('order-layout', OrderLayout)
    .use(svgSpritePlugin);

app.config.globalProperties.$messaging = firebaseMessaging;

app.mount('#app')
    

