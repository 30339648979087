import RepositoryFactory from "../../api/RepositoryFactory";

export default {
  state: {
    giftcards: []
  },

  mutations: {
    SET_GIFTCARDS(state, payload){
      state.giftcards = payload;
    }
  },

  actions: {
    FETCH_GIFTCARDS(context){
      return RepositoryFactory.get('giftcard').all()
      .then(({ data }) => context.commit('SET_GIFTCARDS', data.giftcards))
      .catch(err => console.log(err));
    }
  },

  getters: {
    GIFTCARDS(state){
      return state.giftcards;
    }
  }
  
};
