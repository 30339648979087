import Repository from './../Repository';

const resource = '/auth';

export default {
    checkUnique(payload) {
        return Repository.get(`${resource}/registration/check/unique`, {params: payload})
    },
    registrationCode(payload) {
        return Repository.post(`${resource}/registration/code`, {params: payload})
    },
    verificationEmail(payload) {
        return Repository.post(`${resource}/registration/verification/email`, payload)
    },
    emailVerify(payload) {
        return Repository.put(`${resource}/registration/verification/email/verify`, payload)
    },
    verificationPhone(payload) {
        return Repository.post(`${resource}/registration/verification/phone`, payload)
    },
    phoneVerify(payload) {
        return Repository.put(`${resource}/registration/verification/phone/verify`, payload)
    },
    infoComplete(payload) {
        return Repository.put(`${resource}/registration/info/complete`, payload)
    },
    login(payload) {
        return Repository.post(`${resource}/login`, payload)
    },
    twoFactory(payload) {
        return Repository.post(`${resource}/login/two/factor`, payload)
    },
    sendCodeAgain(payload) {
        return Repository.post(`${resource}/login/send/code/again`, payload)
    },
    forgotPassword(payload) {
        return Repository.post(`${resource}/login/forgot/password`, payload)
    },
    forgotPasswordVerify(payload) {
        return Repository.post(`${resource}/login/forgot/password/verify`, payload)
    },
    changePassword(payload) {
        return Repository.post(`${resource}/login/change/password`, payload)
    }


};
