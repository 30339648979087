<template v-if="loaded">
  <section class="home--welcome__section">
    <div class="row gx-0 justify-content-between">
      <div class="col-md-6 mb-4 mb-md-0">
        <section class="calculation--box ax--card">
          <ax-tabs :tabs="tabs" id="calculator">
            <template v-slot:buy>
              <buy-sell-tab class="mt-4" />
            </template>
            <template v-slot:sell>
              <buy-sell-tab class="mt-4" :sell="true" />
            </template>
            <template v-slot:exchange>
              <exchange-tab class="mt-4" />
            </template>
          </ax-tabs>
        </section>
      </div>
      <div class="col-md-6 home--welcome__section--particles">
        <Particles/>
      </div>
    </div>
  </section>

  <section class="home--news__section">
    <h2 class="home--title">Articles</h2>
    <section class="ax--card news--box">
      <section class="row">
        <div
          class="col-12 col-md-6"
          v-for="(news, key) in HOME_NEWS.splice(0, 2)"
          :key="key"
        >
          <news-item :data="news" />
        </div>
      </section>

      <div class="text-center mt-5">
        <ax-button
          variant="light-bordered"
          :to="{ name: 'News' }"
          append-icon="angle-right"
          icon-stroke="#7A94B8"
          class="p-2 w-25 text-center justify-content-center d-inline-flex"
          >All Articles
        </ax-button>
      </div>
    </section>
  </section>

  <section class="home--features__section">
    <h2 class="home--title">Our Features</h2>
    <section class="row mb-5">
      <div
        class="col-12 col-md-3 m-0"
        v-for="(features, key) in HOME_FEATURES"
        :key="key"
      >
        <features-item :data="features" />
      </div>
    </section>

    <div class="d-flex justify-content-center w-75 mb-5 mx-auto">
      <ax-button class="mt-5 py-3 w-50" :to="{ name: 'Register' }"
        >Sign Up Now</ax-button
      >
    </div>
  </section>
</template>

<script>
import Particles from "../components/home/_particles";
import AxButton from "../components/AxButton";
import AxTabs from "../components/AxTabs";
import SvgIcon from "../components/SvgIcon";
import { mapGetters, mapActions } from "vuex";
import NewsItem from "../components/home/_newsItem";
import FeaturesItem from "../components/home/_featuresItem";
import AxCurrencyBox from "../components/AxCurrencyBox";
import BuySellTab from "../components/home/_tabs/_buy_sell_tab";
import ExchangeTab from "../components/home/_tabs/_exchange_tab";

export default {
  name: "Home",
  components: {
    ExchangeTab,
    BuySellTab,
    AxCurrencyBox,
    NewsItem,
    FeaturesItem,
    SvgIcon,
    AxButton,
    AxTabs,
    Particles,
  },
  data() {
    return {
      tabs: [
        { id: "buy", icon: "arrow-long-down", title: "BUY" },
        { id: "sell", icon: "arrow-long-up", title: "SELL" },
        { id: "exchange", icon: "exchange", title: "EXCHANGE" },
      ],
    };
  },
  created() {
    this.FETCH_HOME_NEWS();
    this.FETCH_HOME_FEATURES();
    this.FETCH_PRICES();
  },
  methods: {
    ...mapActions([
      "FETCH_HOME_NEWS", 
      "FETCH_HOME_FEATURES", 
      "FETCH_PRICES",
    ]),
  },
  computed: {
    ...mapGetters([
      "HOME_NEWS", 
      "HOME_FEATURES"
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "./src/scss/pages/home";
@import "./src/scss/components/home/home-calulcate";
</style>
